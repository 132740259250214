/* eslint-disable import/no-cycle */
import { computed } from 'vue';
import { bind, debounce, throttle } from 'lodash-es';
import fscreen from 'fscreen';
import Emitter from 'tiny-emitter';
import defaultThumbnail from '@/assets/blank_board.png';
import { getPageThumbnail } from '@/lib/utils';
import {
  useConfirmationStore,
  useEditorStore,
  useModalStore,
  useTextSelectStore,
  useUserStore,
} from '@/stores';
import * as types from '@/lib/constants/store';
import * as utils from '@/lib/utils/store';

const emitter = new Emitter();

// eslint-disable-next-line import/prefer-default-export
export const bus = {
  // Lifecycle
  initialized: false,
  initialize() {
    if (this.initialized) return;

    document.addEventListener('mousedown', this.emitMouseDownEvent);
    document.addEventListener('click', this.emitClickEvent);
    document.addEventListener('keyup', this.emitKeyUpEvent);
    document.addEventListener('keydown', this.emitKeyDownEvent);
    window.addEventListener('resize', this.throttleResize);
    window.addEventListener('scroll', this.throttleScroll);
    window.addEventListener('scroll', this.debounceScrollEnd, true);

    if (fscreen.fullscreenEnabled) {
      fscreen.addEventListener('fullscreenchange', bind(this.onFullscreenChange, this));
    }

    this.initialized = true;
  },
  destroy() {
    if (!this.initialized) return;

    document.removeEventListener('mousedown', this.emitMouseDownEvent);
    document.removeEventListener('click', this.emitClickEvent);
    document.removeEventListener('keyup', this.emitKeyUpEvent);
    document.removeEventListener('keydown', this.emitKeyDownEvent);
    window.removeEventListener('resize', this.throttleResize);
    window.removeEventListener('scroll', this.throttleScroll);
    window.removeEventListener('scroll', this.debounceScrollEnd, true);

    if (fscreen.fullscreenEnabled) {
      fscreen.removeEventListener('fullscreenchange', bind(this.onFullscreenChange, this));
    }

    this.initialized = false;
  },

  // Computed
  get draft() {
    if (!this._draft) {
      const editorStore = useEditorStore();
      this._draft = computed(() => editorStore.draft);
    }
    return this._draft;
  },
  get highlights() {
    if (!this._highlights) {
      const textSelectStore = useTextSelectStore();
      this._highlights = computed(() => textSelectStore.highlights);
    }
    return this._highlights;
  },

  // Methods
  draftHasInteractiveModules(draft) {
    if (!draft) return false;
    return utils.draftHasInteractiveModules(draft);
  },
  publishDraft(draftId) {
    useEditorStore()[types.CREATE_CREATION](draftId);
  },
  async [types.GET_CREATION](payload = {}) {
    await useEditorStore()[types.GET_CREATION](payload);
  },
  [types.SET_EDITOR_IS_FULLSCREEN](payload) {
    useEditorStore()[types.SET_EDITOR_IS_FULLSCREEN](payload);
  },
  async [types.VIEW_PAGE_BY_ID](id) {
    await useEditorStore()[types.VIEW_PAGE_BY_ID](id);
  },
  async [types.VIEW_NEXT_PAGE]() {
    await useEditorStore()[types.VIEW_NEXT_PAGE]();
  },
  async [types.VIEW_PREV_PAGE]() {
    await useEditorStore()[types.VIEW_PREV_PAGE]();
  },
  async [types.TOGGLE_EDITOR_DRAWER](payload) {
    await useEditorStore()[types.TOGGLE_EDITOR_DRAWER](payload);
  },
  [types.GET_CONFIRMATION](key) {
    return useConfirmationStore()[types.GET_CONFIRMATION](key);
  },
  [types.OPEN_MODAL](payload) {
    useModalStore()[types.OPEN_MODAL](payload);
  },
  currentPageWithIndex() {
    const editorStore = useEditorStore();
    const { currentPage, currentPageIndex } = editorStore;

    return {
      ...currentPage,
      index: currentPageIndex,
    };
  },
  debounceScrollEnd: debounce(() => {
    emitter.emit('after:scroll');
  }, 100),
  emitClickEvent(e) {
    emitter.emit('document:click', e);
  },
  emitMouseDownEvent(e) {
    emitter.emit('document:mousedown', e);
  },
  emitKeyUpEvent(e) {
    emitter.emit('document:keyup', e);
  },
  emitKeyDownEvent(e) {
    emitter.emit('document:keydown', e);
  },
  async goToPrevPage(callback) {
    await this[types.VIEW_PREV_PAGE]();

    if (callback) callback(this.currentPageWithIndex());
  },
  async goToNextPage(callback) {
    await this[types.VIEW_NEXT_PAGE]();

    if (callback) callback(this.currentPageWithIndex());
  },
  async goToPageById(id, callback) {
    await this[types.VIEW_PAGE_BY_ID](id);

    if (callback) callback(this.currentPageWithIndex());
  },
  async goToSection(id, { isAnimated = true } = {}) {
    const editorStore = useEditorStore();
    const { pagesSorted } = editorStore;

    const firstPageInSection = pagesSorted.find((page) => (
      page.options && page.options.group_id === id
    ));

    if (firstPageInSection) {
      editorStore[types.SET_EDITOR_CAROUSEL_IS_ANIMATED](isAnimated);
      await this[types.VIEW_PAGE_BY_ID](firstPageInSection.id);
      editorStore[types.SET_EDITOR_CAROUSEL_IS_ANIMATED](true);
    }
  },
  loadCreation(id) {
    this[types.GET_CREATION]({ id });
  },
  throttleResize: throttle(() => {
    emitter.emit('window:resize');
  }, 100),
  throttleScroll: throttle(() => {
    emitter.emit('window:scroll');
  }, 100),
  onFullscreenChange() {
    if (!fscreen.fullscreenElement) {
      this[types.SET_EDITOR_IS_FULLSCREEN]({ isFullscreen: false });
    }
  },
  dispatchWindowResize() {
    const event = document.createEvent('UIEvents');
    event.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(event);
  },
  thumbnailForPage(page) {
    // This returns a promise that resolves to the thumbnail url
    return getPageThumbnail(page, defaultThumbnail);
  },
  openPrintLayoutModal() {
    this[types.OPEN_MODAL]({
      type: 'PrintLayoutModal',
    });
  },
  openMyContentModal(asset) {
    if (!asset) return;
    const userStore = useUserStore();
    const modalStore = useModalStore();
    modalStore[types.SET_MY_CONTENT_MODAL_OPTIONS]({
      asset: {
        actions: [],
        selectedAssetId: asset.id,
        selectedAssetTitle: asset.title,
        sharingEnabled: true,
      },
      config: {
        hideDistrictTab: userStore.region === 'UK', // see DEX-2646
        isActive: true,
        isStudent: userStore.userIsStudent,
        modalType: 'save',
      },
    });
  },
  // Emitter methods
  emit(...args) {
    emitter.emit(...args);
  },
  on(...args) {
    emitter.on(...args);
  },
  off(...args) {
    emitter.off(...args);
  },
};
